import { NavLink } from "react-router-dom";
import './Nav.css';

function Nav() {
  const handleActive = (act) => {
    return act ? 'navLinkActive-Nav navLink-Nav' : 'navLink-Nav'
  }
  
  return (
    <nav id="NavsConatiner-Nav">
      
      <NavLink 
        className={({ isActive }) => (handleActive(isActive))} 
        to="/"
      > 
        home
      </NavLink>

      <NavLink 
        className={({ isActive }) => (handleActive(isActive))} 
        to="/things"
      > 
        things/installs
      </NavLink>

      <NavLink 
        className={({ isActive }) => (handleActive(isActive))} 
        to="/videos"
      > 
        videos
      </NavLink>

      <NavLink 
        className={({ isActive }) => (handleActive(isActive))} 
        to="/sounds"
      > 
        sounds
      </NavLink>
      
      <NavLink 
        className={({ isActive }) => (handleActive(isActive))} 
        to="/webprojects"
      > 
        webprojects
      </NavLink>
      
    </nav>
  )
}

export default Nav