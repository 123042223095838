import Nav from "../Nav/Nav.jsx";
import { useLocation } from "react-router-dom";
import {  ReactP5Wrapper } from "@p5-wrapper/react";
import Bg from "../../scripts/newBgLines.js";
import RandomBGLines from "../RandomBGLines/RandomBGLines.jsx";
import "./Layout.css";
import { useEffect, useState } from "react";

function Layout(props) {
  let location = useLocation();
  
  return (
    <div id="container-Layout">
      {/* <ReactP5Wrapper 
        sketch={Bg}
        id='p5Bg-Layout'
      >
      </ReactP5Wrapper> */}
      <RandomBGLines reRender={location}/>
      <Nav />
      <div id="body-Layout">
        {props.children}
      </div>
    </div>
  );
}

export default Layout;
