import {useWindowDimensions} from "../../services/helpFunctions.js";
import psPalImg from "../../assets/radio/pSpacesLogo_23_freePal.png"
import psPalColorImg from "../../assets/radio/pSpacesLogo_23_freePalCOLOR.png"
import psYellowImg from "../../assets/radio/pSpacesLogo_23.png"
import Radio from "../../components/Radio/Radio.jsx"
import './Sounds.css';

function Sounds() {
  let screenSize = useWindowDimensions();
  const handleScreenSize = () => {
    if(screenSize.width < 600) {
      return "80vw"
    } else {
      return "40vw"
    }
  }
  return (
    <div id="mainContainer-Sounds">
      <div className="screenHeader-App">SOUNDS</div>
     
      <div className="iframeContainer-Sounds">
        <div className="descriptionBox-Sounds">
          'Composing' 2019. Full Hour loop from sound installation. <br/>
          HBK MontageHalle. Braunschweig, Germany 
        </div>
        <iframe 
          className="iframeSoundcloud-Sounds"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/714478312&color=%23d8d9d9&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
          title="Composing install recording"
        ></iframe>
      </div>
      <div className="descriptionBox-Sounds radioShow-Sounds" >
        Possibility Spaces Radio Show. <br/>
        Bi-weekly radio residency at Newtown Radio <br/>
        Brooklyn, NY
      </div>
      
      <Radio 
        title="Palestine Solidarity 2024.01.03"
        link="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&hide_artwork=1&feed=%2Fnewtownradiobk%2Fpossibility-spaces-20240103%2F"
        image={psPalImg}
        imgAlt="PSpaces logo with the iconic Handala cartoon in solidarity with Palestine"
        width={handleScreenSize()}
      />

      <Radio 
        title="Palestine Solidarity 2023.10.25"
        link="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&hide_artwork=1&feed=%2Fnewtownradiobk%2Fpossibility-spaces-palestine-solidarity-episode-2023-october-25th%2F"
        image={psPalColorImg}
        imgAlt="PSpaces logo with the iconic Handala cartoon in solidarity with Palestine"
        width={handleScreenSize()}      
      />

      <Radio 
        title="Possibility Spaces 2023.12.18"
        link="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&hide_artwork=1&feed=%2Fnewtownradiobk%2Fpossibility-spaces-18012023%2F"
        image={psYellowImg}
        imgAlt="Possibility Spaces radio show logo, handwritten in a sribbly style with a yellow to white gradient"
        width={handleScreenSize()}
      />
      
      <div className="iframeContainer-Sounds">

        <div className="descriptionBox-Sounds" >
          Live Sound Performance <br/>
          Cafe LaMama, live broadcast, April 7th 2020  <br/>
          New York, NY
        </div>
        <iframe 
          className="video-Sounds"
          src="https://www.youtube.com/embed/49CsMjrjaeI?controls=0&amp;start=3313"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
          title="Jeremy Wiles-Young performance cafe la mama"
        ></iframe>
      </div>

    </div>
  )
}

export default Sounds;