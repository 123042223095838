import {useState} from 'react';
import {useWindowDimensions} from "../../services/helpFunctions.js";
import idxAsa from "../../assets/installations/asa-box/index.js";
import idxBC from '../../assets/installations/BCollege/index.js';
import idxBraun from '../../assets/installations/Braun/index.js';
import idxFabric from '../../assets/fabric/index.js';
import idxPrints from '../../assets/prints/index.js';
import Gallery from '../../components/Gallery/Gallery.jsx';
import './Things.css';

function Things() {
  // set the tabIdx
  const [tabIdx, setTabIdx] = useState(0);

  // get screen height and width
  const screen = useWindowDimensions();
  
  // toggle between active classes for the tabs
  const handleTabActive = (num) => {
    if(tabIdx === num) {
      return "tabsActive-Things tabs-Things"
    } else {
      return "tabs-Things"
    }
  }
  const handleWidthResize = () => {
    let str = '';
    if(screen.width < 600) {
      str = "90vw"
    } else {
      str = "60vw"
    }
    return str
  }
  
  return (
      <div id="mainContainer-Things">
        <div className="screenHeader-App">THINGS / INSTALLS</div>
        <div id="tablist-Things">
          <div 
            className={handleTabActive(0)} 
            onClick={() => setTabIdx(0)}
          >Installations/Sculptures</div>
          <div 
            className={handleTabActive(1)}
            onClick={() => setTabIdx(1)} 
          >Fabric + Furniture</div>
          <div 
            className={handleTabActive(2)}
            onClick={() => setTabIdx(2)} 
          >Prints</div>
        </div>
          
          {/* tab 1 installations */}
          {
          (tabIdx === 0) && <div className="tabPanels-Things">
            <div className="title-img-Things"> 
              <strong>'asa-box'</strong> <br/>
              Sound Sculpture: wood, raspberry pi, speakers<br/>
              January 2024. <br />  
              <a 
                className='gitLink-Things'
                href="https://github.com/jwow1000/asa-box"
                target='_blank'
                rel='noreferrer'
              >↗ github link</a>
            </div>
            <Gallery images={idxAsa} width={ handleWidthResize() }/>

            <div className="title-img-Things"> 
              <strong>'Composing'</strong> <br/>
              "Every hundred feet the world changes". <br/>
              HBK-Montagehalle, Braunschweig Germany.<br /> October 2019.   
              </div>
            <Gallery images={idxBraun} width={handleWidthResize()}/>
            <iframe 
               src="https://www.youtube.com/embed/6RsFgDLuXaA?si=0lBgIdkiYPzCG01r&amp;controls=0" 
               title="YouTube video player" 
               frameborder="0" 
            >
            </iframe> 

            <div className="title-img-Things"> 
              <strong>'Tent-001'</strong>  <br/>
              Group Show "Chain Reaction-Brooklyn College MFA". <br/>
              SIGNAL GALLERY Brooklyn, NY. MAY 2015 
            </div>
            <Gallery images={idxBC} width={handleWidthResize()} />
            
          </div>
          }
          {/* tab 2 Fabric + Furniture */}
          {
            (tabIdx === 1) && idxFabric.map((item, idx) => (
              <div className="imageContainer-Things">
                <img
                  className="image-Things" 
                  src={item} 
                  alt="fabric pieces by jeremy wy" 
                  key={`fabric-${idx}`} 
                />
              </div>
            ))
          }
          {/* tab 2 Fabric + Furniture */}
          {
            (tabIdx === 2) && idxPrints.map((item, idx) => (
              <div className="imageContainer-Things">
                <img
                  className="image-Things" 
                  src={item} 
                  alt="various prints by jeremy wy" 
                  key={`prints-${idx}`} 
                />
              </div>
            ))
          }
      
    </div>
  );
}

export default Things