import a from "./pattern-001.jpg"
import b from "./pattern-001(back).jpg"
import c from "./pattern-002.png"
import d from "./pattern-002(2).jpg"
import e from "./pattern-003.jpg";
import f from "./pattern-004.jpg";
import g from "./pete1.jpg";
import h from "./k-and-n-black.jpg";
import i from "./k-and-n-white.jpg";
import j from "./rebec-shirt.jpg";
import k from "./rebec-shirt-2.jpg";
import l from "./bench-001(front).png";
import m from "./stool-001.png";

const arr = [a,b,c,d,e,f,g,h,i,j,k,l,m];

export default arr;