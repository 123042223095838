import {useWindowDimensions} from "../../services/helpFunctions.js";
import jwyImg from "../../assets/logos/jwy_logo_24.png";
import jwyCV from "../../assets/cv/JWY_CV_2024-04-24.pdf"
import br1 from "../../assets/installations/Braun/1.jpg";
import br2 from "../../assets/installations/Braun/2.jpg";
import git from "../../assets/logos/github.png";
import pSpace from "../../assets/radio/PSIMAGE-01-19-2022.png";
import psPalColorImg from "../../assets/radio/pSpacesLogo_23_freePalCOLOR.png"
import Radio from "../../components/Radio/Radio.jsx";
import "./Home.css";

function Home() {
  let screenSize = useWindowDimensions();
  const handleScreenSize = () => {
    if(screenSize.width < 600) {
      return "50vw"
    } else {
      return "20vw"
    }
  }
  return (
    <div id="mainContainer-Home">
      <div className="screenHeader-App">
        JEREMY WILES-YOUNG
      </div> 
      <div className="imgContainer-Home">
        <img 
          src={jwyImg}
          className="img-Home"
          alt="decorative hand drawn text of the text: Jeremy Wiles-Young"
        ></img>
      </div>

      <div className="imgContainer-Home">
        <img 
          src={br1}
          className="img-Home"
          alt="install shot of Composing by Jeremy Wiles-Young"
        ></img>
      </div>
      
      <div id="bio-Home">
        artist and software engineer based in brooklyn, ny. 
      </div>
      
      <Radio 
        title="Palestine Solidarity 2023.10.25"
        link="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&hide_artwork=1&feed=%2Fnewtownradiobk%2Fpossibility-spaces-palestine-solidarity-episode-2023-october-25th%2F"
        image={psPalColorImg}
        imgAlt="PSpaces logo with the iconic Handala cartoon in solidarity with Palestine"
        width={handleScreenSize()}
      />

      <a 
        id="gitLink-Home" 
        href="https://github.com/jwow1000"
        target='_blank'
        rel="noreferrer"
        aria-label='Github'
      >
        <img 
          src={git} 
          alt="jeremy wiles-young's github link" 
          id="gitImg-Home"
        />
        <br/>link to my github
      </a>
      
      <div className="imgContainer-Home">
        <img 
          src={br2}
          className="img-Home"
          alt="install shot of Composing by Jeremy Wiles-Young"
        ></img>
      </div>
      <div className="imgContainer-Home">
        <img 
          src={pSpace}
          className="img-Home"
          alt="cover art for Possibility Spaces radio show"
        ></img>
      </div>
      <a 
        href={jwyCV}
        id="downloadCV-Home"
      > 
        CLICK TO DOWNLOAD MY RESUME 
      </a>
      
    </div>
  
  )
}

export default Home;