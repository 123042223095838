import { useState, useEffect } from 'react';


// --- ------ ----------------- get window size with resize function
function getWindowDimensions() {
  const { innerWidth, innerHeight } = window;
  // console.log('window', innerHeight)
  return { width: innerWidth, height: innerHeight};
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      // console.log('hand dimen', getWindowDimensions())
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
