import Home from "./screens/Home/Home.jsx";
import Things from "./screens/Things/Things.jsx";
import Videos from "./screens/Videos/Videos.jsx";
import Sounds from "./screens/Sounds/Sounds.jsx";
import Webprojects from "./screens/WebProjects/WebProjects.jsx";
import Layout from "./components/Layout/Layout.jsx";
import Subway from "./screens/Subway/Subway.jsx";
import { Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="App">
      <Layout id="layout-App">
        <Routes id='routes-App'>
          <Route path="/" element={<Home />} />
          <Route path="/things" element={ <Things /> } />
          <Route path="/videos" element={ <Videos /> } />
          <Route path="/sounds" element={ <Sounds /> } />
          <Route path="/webprojects" element={ <Webprojects /> } />
          <Route path="/subway" element={<Subway/>} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
