import './Videos.css';

const vidLinks = [
  "https://www.youtube.com/embed/uAKv80hS3MI",
  "https://www.youtube.com/embed/dt53waP6bAU",
  "https://www.youtube.com/embed/VUsnJllAc3Y",
  "https://www.youtube.com/embed/kYxAFf1hwbw",
  "https://www.youtube.com/embed/w8BYVD6W_ww",
  "https://www.youtube.com/embed/JGGjwgE3sqo",
  "https://www.youtube.com/embed/OpnnBG25MQk",
  "https://www.youtube.com/embed/klxSH5Uv6hw"
]

function Videos() {
  return (
    <div id="mainContainer-Videos">
      <div className="screenHeader-App">
        VIDEOS
      </div>
      {
        vidLinks.map((item, idx) =>(
          <div 
            className='iframeContainer-Videos'
            key={idx}
          >
          
            
            <iframe 
              className="iframe-Videos"
              title={`iframe-${idx}?si=OxmkI45idtICBXji&amp;controls=0;frameborder=0`}
              src={`${item}`} 
              
            >

            </iframe>
          </div>
        ))
      }
      

    </div>
  )
}

export default Videos;