import a from "./1.jpg";
import b from "./2.jpg";
import c from "./3.jpg";
import d from "./4.jpg";
import e from "./5.jpg";
import f from "./6.jpg";
import g from "./7.jpg";
import h from "./8.jpg";
import i from "./9.jpg";
import j from "./10.jpg";

const arr = [a,b,c,d,e,f,g,h,i,j];

export default arr;