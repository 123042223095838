import { useEffect, useState, useRef } from 'react'
import './Gallery.css'

function Gallery({images, width}) {
  const imgRef = useRef(null);
  const [imgs, setImgs] = useState([]);
  const [count, setCount] = useState(0);
  const [idx, setIdx] = useState(0);
  
  // count images and load
  useEffect(() => {
    setImgs(images);
    setCount(imgs.length);
    // console.log('image load', imgs, imgs.length)
  }, [imgs, images])
  
  // handle left click
  const handleLeftClick = () => {
    setIdx((prev) => {
      if((prev - 1) < 0) {
        return count - 1;
      } else {
        return prev - 1;
      }
    })
   
  }
  const handleRightClick = () => {
    // console.log('right click!')
    setIdx((prev) => {
      if((prev + 1) > count - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    })
  }

  // -------------------------------- full screen stuff ----------------
  // define enter fullscreen function
  const enterFullscreen = () => {
    const elem = imgRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };

  // define exit fullscreen function
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  // handle full screen toggle
  const handleFullscreen = () => {
    console.log("fullscreeeen!")
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  // text for functionality description
  const leftStr = "<- click - -";
  const centerStr = "- - click for fullscreen - - ";
  const rightStr = "- - click ->";
  
  // return react
  return (
    
    <div 
      id="mainContainer-Gallery"
      style={{
        'width': `${width}`,
        'height': `${width}`
      }}
    >
      <p 
        id="leftLabel-Gallery" 
        className="labels-Gallery" 
        onClick={handleLeftClick}
      > 
        {leftStr}
      </p>
      
      <p 
        id="centerLabel-Gallery" 
        className="labels-Gallery" 
        onClick={handleFullscreen}
      > 
        {centerStr}  
      </p>
      <p 
        id="rightLabel-Gallery" 
        className="labels-Gallery"
        onClick={handleRightClick}
      > 
        {rightStr}   
      </p>

      <div id="leftClick-Gallery" onClick={handleLeftClick}></div>
      <div id="centerClick-Gallery" onClick={handleFullscreen} ></div>
      <div id="rightClick-Gallery" onClick={handleRightClick}></div>
      
      <img 
        id="image-Gallery"
        src={imgs[idx]} 
        alt="jeremy wy art"
        ref={imgRef}
      />
    </div>
  
        
  )
}

export default Gallery