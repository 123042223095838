import './Radio.css';

function Radio({title, link, image, width, imgAlt}) {
  return (
    <div 
      id="mainContainer-Radio" 
      style={{
        "width": width
      }}
    >
      <img 
        className="radioImg-Radio"
        src={image} 
        alt={imgAlt} 
      />
      <iframe 
        className="iframe-Radio"
        src={link} 
        title={title} 
        style={{
          "width": width
        }}
      ></iframe>
    </div>
  )
}

export default Radio