
import { Link, NavLink } from 'react-router-dom';
import subwayImg from '../../assets/internet/subway-sHack.png';
import researchImg from '../../assets/internet/reSearch-sHack.png';
import chloeImg from '../../assets/internet/chloeWebsiteSH.png';
import rrImg from '../../assets/internet/trr-sHack.png';
import './WebProjects.css'

// list of web projects, array of objects
const webP = [
  {
    name:'reSearch ↗',
    mainLink: "https://discogs-research.netlify.app/",
    description: "A visual way to browse the discogs database. And a bonus random feature. with links to youtube, spotify, itunes music for a complete-ish user experience.",
    gitLink: "https://github.com/jwow1000/research-discogs",
    image: researchImg
  },
  {
    name:'Chloë Engel Offical Website ↗',
    mainLink: "https://chloeengel.info",
    description: "Chloë Engel artist and dancer's portfolio page. Three.js, React.js",
    gitLink: "https://github.com/jwow1000/chloe-engel-website",
    image: chloeImg
  },
  {
    name: 'The Real Reel ↗',
    mainLink: "https://therealreel.netlify.app/",
    description: 'A social web app where users can find and review movies. user auth and verification. React.js, MonogDB, Express',
    gitLink: "https://github.com/ambretate/the-real-reel-front-end",
    image: rrImg
  },


];

function WebProjects() {
  return (
    <div id="mainContainer-WebProjects">
      <div className="screenHeader-App">WEB PROJECTS</div>    
      <div id="projectContainer-WebProjects">

        {
          webP.map((item, idx) => (
            <div 
              className="linkContainer-WebProjects"
              style={{"backgroundImage":`url(${item.image})`}}
            >
              <a 
                className='webLink-WebProjects'
                href={item.mainLink}
                target="_blank"
                rel="noreferrer"
              > {item.name} </a>
              <p className='linkDescrip-WebProjects'>
                {item.description}
              </p>
              <Link
                to={item.gitLink}
                target="_blank"
                rel="noreferrer"
              >
                <div className='linkGit-WebProjects'></div>
              </Link>
            </div>
          ))

        }
        
        <NavLink 
          className="linkContainer-WebProjects"
          to="/subway"
          style={{
            "textDecoration": "none",
            "backgroundImage": `url(${subwayImg})` 
          }}
        >
          <div className="webLink-WebProjects">
            SUBWAY SPACE
          </div>
          <p className='linkDescrip-WebProjects'>
            an array of embedded youtube videos, to create an interactive video playback experience
          </p>
        </NavLink> 
      </div>
      
    </div> 

  )
}

export default WebProjects;
